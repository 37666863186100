export default [
	{
		id: "luanda",
		name: "Luanda",
		address:
			"Edifício Kilamba, 20º andar<br />Avenida 4 de Fevereiro (Marginal)<br />Luanda - Angola",
		latitude: -8.810882331073492,
		longitude: 13.23151708465853,
		phoneNumbers: "+244 222 372 030 / 57 / 92",
		faxNumbers: "+244 222 372 017",
		email: "luanda@fatimafreitas.com",
		pin: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.732873705975!2d13.229383515586761!3d-8.811147092557313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f3ae17c400cb%3A0x9585a058792b5e19!2sEdif%C3%ADcio%20Kilamba!5e0!3m2!1spt-PT!2spt!4v1570561564542!5m2!1spt-PT!2spt",
	},
	{
		id: "benguela",
		name: "Benguela",
		address:
			"Rua Domingos do Ó, n.º 61, “Edifício Acácias Place”, Bloco 2, n.º 13, 1º andar<br />Benguela - Angola",
		latitude: -12.58431871789257,
		longitude: 13.406492271164634,
		phoneNumbers: "+244 272 226 705",
		faxNumbers: "+244 272 226 706",
		email: "lobito@fatimafreitas.com",
		pin: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d486.74563792440483!2d13.4063841!3d-12.5845516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1bafd10a14c4bc31%3A0xebcc6ddc8208266a!2sR.%20Domingos%20do%20%C3%93%2C%20Benguela!5e0!3m2!1spt-PT!2sao!4v1634124587426!5m2!1spt-PT!2sao",
	},
	{
		id: "cabinda",
		name: "Cabinda",
		address: "Rua de Macau, S/N <br />Cabinda - Angola",
		latitude: -5.559803066413132,
		longitude: 12.192848617333453,
		phoneNumbers: "+244 31 24 179",
		faxNumbers: "+244 31 22 344",
		email: "cabinda@fatimafreitas.com",
		pin: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2261.0906450341226!2d12.191908408136772!3d-5.560287939719906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a5dd3a6d7be96f9%3A0x666c4c426ca6c6d!2sR.%20de%20Macau%2C%20Cabinda!5e0!3m2!1spt-PT!2sao!4v1596813503878!5m2!1spt-PT!2sao",
	},
];
